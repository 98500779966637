import React, { useEffect, useState } from 'react';

const BookingListPage = () => {
    const [bookings, setBookings] = useState([]);


    useEffect(() => {
        // ToDo: Make API call to fetch all bookings
        // use fetch or axios to make a GET request to"/bookings" endpoint.
        // and update the state with the fetched bookings
        const fetchBookings = async () => {
            try {
                const response = await fetch('/bookings');

                if (response.ok) {
                    const data = await response.json();
                    setBookings(data);
                } else {
                    console.error('Failed to fetch bookings:', response.status);
                }
            } catch (error) {
                console.error('Error fetching bookings:', error);
            }
        };
        fetchBookings();
   }, []);

return (
    <div>
        <h2>All Bookings</h2>
        <ul>
            {bookings.map((booking) => (
              <li key={booking.id}>
                {/*  Display booking details here */}
                {/* booking, title, booking.firstName, etc. */}
                <p>Title: {booking.title}</p>
                <p>First Name: {booking.firstName}</p>
                <p>Surname: {booking.surname}</p>
                <p>Email: {booking.email}</p>
              </li> 
            ))}
        </ul>
    </div>
);
};

export default BookingListPage;