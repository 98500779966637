import React, { useState } from 'react';

const BookingFormPage = () => {
    const [booking, setBooking] = useState({
        title: "",
        firstName: "",
        surName: "",
        email: "",
        roomId: "",
        checkInDate: "",
        checkOutDate: "",
  });

const handleSubmit = async (e) => {
    e.preventDefault();
// ToDo: Make API call to create a new booking
// use fetch or Axios to make a post request to "bookings" endpoint.
// with the booking data
try {
    const response = await fetch('/bookings', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(booking),
    }) 
    if (response.ok) {
        console.log('Booking created successfully');
        setBooking({
            title: '',
            firstName: '',
            surName: '',
            email: '',
            roomId: '',
            checkInDate: '',
            checkOutDate: '',
        });
    } else {
        console.error('Failed to create booking:', response.status);
    }
} catch (error) {
    console.error('Error creating booking:', error);
}
};

const handleChange = (e) => {
    const { name, value } = e.target;
    setBooking((prevBooking) => ({...prevBooking, [name]: value }));
};

return (
    <div>
        <h2>Create a new Booking</h2>
        <form onSubmit={handleSubmit}>
            {/* Add form fields here (title, firstName, surName, etc) */}
            <label>
                Title:
                <input type="text" name="title" value={booking.title} onChange={handleChange} />
                </label>
                <label>
                First Name:
                <input type="text" name="firstName" value={booking.firstName} onChange={handleChange} />
                </label>
                <label>
                Last Name:
                <input type="text" name="lastName" value={booking.lastName} onChange={handleChange} />
                </label>
                <label>
                Email:
                <input type="text" name="Email" value={booking.email} onChange={handleChange} />
                </label>
                <label>
                roomId:
                <input type="text" name="roomId" value={booking.roomId} onChange={handleChange} />
            </label>
            <label>
            checkInDate:
           <input type="text" name="checkInDate" value={booking.checkInDate} onChange={handleChange} />
            </label>
            <label>
                checkOutDate:
                <input type="text" name="checkOutDate" value={booking.checkOutDate} onChange={handleChange} />
            </label>

            <button type="submit">Create Booking</button>
        </form>
    </div>
);
};

export default BookingFormPage;