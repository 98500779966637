import React, { useState } from 'react';

const SearchPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [searchResults, setSearchResults] = useState([]);


    const handleSearch = async (e) => {
        e.preventDefault();

        try {
            let queryString = '';
            if (searchTerm) {
                queryString += `term=${encodeURIComponent(searchTerm)}`
            }

            const response = await fetch(`/bookings/search?${queryString}`);

            if (response.ok) {
                const data = await response.json();
                setSearchResults(data);
            } else {
                console.error('Failed to search bookings:', response.status);
            }
        } catch (error) {
        console.error('Error searching booking:', error)
        // ToDo: Make API call to search for bookings
        // use fetch or Axios to make a GET request to "/bookings/search"
        // with searchTerm or searchDate as query parameter
        //  update the state with the fetched bookings
        }
    };

    return (
        <div>
            <h2>Search Bookings</h2>
            <form onSubmit={handleSearch}>
                <input type="text" placeholder="Search by term..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

                <input type="date" value={searchDate} onChange={(e) => setSearchDate(e.target.value)} />
                {/* Add form fields for searchTerm and searchDate */}
                <button type="submit">search</button>
            </form>

            {searchResults.length > 0 && (
                <div>
                    <h3>Search Results:</h3>
                    <ul>
                        {searchResults.map((booking) => (
                        <li key={booking.id}>
                            <p>Title: {booking.title}</p>
                            <p>First Name: {booking.firstName}</p>
                            <p>Surname: {booking.surname}</p>
                            <p>Email: {booking.email}</p>
                        </li>    
                        ))}
                    </ul>
                   </div>
            )}
            </div>
         );
          };

   export default SearchPage;